<template>

    <div class="mallCell">

        <div class="mallCellContent">

            <div class="image">
                <img :src="GLOBAL.BASE_URL + item.renewlog.img"></img>
            </div>
            <div class="mallInfo">
                <div class="title1">
                    <span>
                        {{item.renewlog.title}}
                    </span>
                    <div>
                        <span class="title3">
                            ¥<span class="heavy">{{priceDot(0)}}</span>.{{priceDot(1)}}
                        </span>
                    </div>
                </div>
                <div class="title2">
                    <span>
                        {{item.renewlog.description}}
                    </span>

                    <!-- <span>{{ticketStatus}}</span> -->
                </div>
            </div>

        </div>

        <span class="orderInfo">
            订单编号
            <span>
                {{item.order_no}}
            </span>
        </span>
        <!-- <span class="orderInfo" v-if="item.pay_time">
            下单时间
            <span>
                {{item.pay_time}}
            </span>
        </span> -->

    </div>

</template>

<script>
    import {
        mapState,
        mapMutations
    } from 'vuex'

    export default {
        components: {

        },
        name: "",
        props: ["item"],
        data() {
            return {

            };
        },
        computed: {
            ticketStatus() {

                //  status //0待审核、1待开票、2已开票、3已拒绝, 4已关闭  

                switch (this.item.status) {
                    case 0:
                        return "待审核"
                        break;
                    case 1:
                        return "待开票"
                        break;
                    case 2:
                        return "已开票"
                        break;
                    case 3:
                        return "已拒绝"
                        break;
                    default:
                        return "已关闭"
                        break;
                }



            }

        },

        methods: {

            priceDot(index) {

                if (this.item && this.item.price) {

                    var a = String(this.item.price).split('.')
                    return a[Number(index)]

                } else {

                    return "0"
                }

            },

            jump() {
                // uni.navigateTo({
                //     url: '/subpkg/jxw/mall/mall/goods'
                // })
            },

            changeValue(e) {

            }

        }

    }
</script>

<style lang="scss" scoped>
    // .mallCell::after {
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     bottom: 0px;
    //     // height: 20px;
    //     background-color: #F1F1F1;

    //     border: 1px red solid;
    //     box-sizing: border-box;
    // }

    .mallCell {
        // border: 1px red solid;
        box-sizing: border-box;

        // padding: 35px 30px;
        // width: 100vw;
        width: 100%;
        // margin-bottom: 20px;
        background: #fff;
        // border-radius: 20px;
        // overflow: visible;
        position: relative;

        .mallCellContent {
            // display: inline-block;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }





        img {
            width: 60px !important;
            height: 60px !important;
            // display: inline-block;
            display: block;
            border-radius: 6px;

            // border: 1px red solid;
            // box-sizing: border-box;

            background-color: #000;
        }


        .mallInfo {
            // border: 1px red solid;
            box-sizing: border-box;
            padding: 0px 0px 0px 10px;
            flex: 10;

            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .title1 {
                // border: 1px red solid;
                box-sizing: border-box;

                // display: inline-block;

                font-size: 14px;
                font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                font-weight: bold;
                color: #2D2D2D;
                width: 100%;

                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            .title2 {
                // border: 1px red solid;
                box-sizing: border-box;

                // display: inline-block;

                font-size: 12px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #9B9B9B;

                width: 100%;

                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            // .title2::after {
            //     content: "›";
            //     padding-left: 5px;
            //     font-size: 30px;
            // }

            .title3 {
                // border: 1px red solid;
                // box-sizing: border-box;
                display: inline-block;

                font-size: 12px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 600;
                // color: #E23B2F;
                color: #000;
                width: 100%;

                // margin-top: 0px;

                .heavy {
                    font-size: 26px;
                }
            }

        }


        .orderInfo {
            // border: 1px red solid;
            box-sizing: border-box;
            // padding-left: 60px;
            // width: 80vw;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #9F9F9F;
            line-height: 20px;
            display: block;
            margin-top: 10px;

            span {
                margin-left: 10px;
                color: #000;
            }
        }

    }
</style>